import React from 'react';
import MistakenIcon from '../assets/images/mistake.png';

const UsernameWrongEmail = () => {
  return (
    <>
      <section className="loginWrong">
        <div className="loginWrong_wrapper-outer">
          <div className="loginWrong_wrapper-inner">
            <img src={MistakenIcon} alt="" />
            <span className="loginWrong-message">Make sure the email you entered is correct</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default UsernameWrongEmail;
