import React from 'react';
import { Link } from 'gatsby';

import LoginPic from '../assets/images/login-picture.jpg';

import ForgotUsername from '../components/ForgotUsername';
import Footer from '../components/Footer';
import UsernameWrongEmail from '../components/UsernameWrongEmail';

const ForgotUserWrongEmail = () => {
  return (
    <>
      <section className="section">
        <div className="section-wrapper_outer">
          <div className="section-img">
            <img src={LoginPic} alt="" />
            <h1>Knowledge is power and freedom</h1>
          </div>
          <div className="section-wrapper_inner">
            <div className="section-container">
              <div className="section-container-inner">
                <UsernameWrongEmail />
              </div>
              <div className="section-container-inner">
                <ForgotUsername />
              </div>
              <div className="section-container-inner">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotUserWrongEmail;
